<template>
  <v-container>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form class="p-2" @submit.prevent="submit">
        <validation-provider v-slot="{ errors }" name="Aircraft" rules="required">
          <v-text-field
            v-model="folio_entries.aircraft_id"
            :error-messages="errors"
            label="Aircraft"
            required
            outlined
            dense
          >
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Defect" rules="required">
          <v-text-field v-model="folio_entries.defect" :error-messages="errors" label="Defect" required outlined dense>
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Rectification" rules="required">
          <v-text-field
            v-model="folio_entries.rectification"
            :error-messages="errors"
            label="Rectification"
            required
            outlined
            dense
          >
          </v-text-field>
        </validation-provider>

        <v-btn type="submit" color="primary" class="ma-10 pa-5" :disabled="invalid">
          Update
        </v-btn>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import util from "@/core/utils/misc";
import Request from "@/core/api/request";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "FolioEntryRead",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return { folio_entries: {} };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      const { data } = await Request.get(`${this.$IYA.URI.FOLIO_ENTRIES__READ}`, { id: this.$route.params.id });
      this.folio_entries = data;
    },
    async submit() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait while we process." });
        await Request.post(`${this.$IYA.URI.FOLIO_ENTRIES__EDIT}`, this.folio_entries).then(response => {
          if (response.status === this.$IYA.HTTP.OK) {
            this.$swal(`Successful`, `Action approved`, `success`);
          }
        });
        util.isLoading();
      }
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
