var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Aircraft","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Aircraft","required":"","outlined":"","dense":""},model:{value:(_vm.folio_entries.aircraft_id),callback:function ($$v) {_vm.$set(_vm.folio_entries, "aircraft_id", $$v)},expression:"folio_entries.aircraft_id"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Defect","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Defect","required":"","outlined":"","dense":""},model:{value:(_vm.folio_entries.defect),callback:function ($$v) {_vm.$set(_vm.folio_entries, "defect", $$v)},expression:"folio_entries.defect"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Rectification","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Rectification","required":"","outlined":"","dense":""},model:{value:(_vm.folio_entries.rectification),callback:function ($$v) {_vm.$set(_vm.folio_entries, "rectification", $$v)},expression:"folio_entries.rectification"}})]}}],null,true)}),_c('v-btn',{staticClass:"ma-10 pa-5",attrs:{"type":"submit","color":"primary","disabled":invalid}},[_vm._v(" Update ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }